.education__container{
    max-width: 768px;
}

.education__tabs{
    display:flex;
    justify-content: center;
    margin-bottom: var(--mb-2);
}

.education__button{
    font-size: var(--h3-font-size);
    font-weight:var(--font-medium);
    color:var(--title-color);
    margin: 0 var(--mb-1);
    cursor:pointer;
}

.education__button:hover{
    color: var(--title-color-dark);
}

.education__icon{
    font-size:1.8rem;
    margin-right:var(--mb-0-25);
}

.education__active{
    color:var(--title-color-dark)
}
.education__content{
    display:none;
}

.education__content-active{
    display:block;
}

.education__data{
    display:grid;
    grid-template-columns: 1fr max-content 1fr;
    column-gap: 1.5rem;
}

.education__title{
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
}

.education__subtitle{
    display:inline-block;
    font-size:var(--small-font-size);
    margin-bottom: var(--mb-1);
}

.education__calender{
    font-size: var(--small-font-size);
}

.education__rounder{
    display:inline-block;
    width: 13px;
    height: 13px;
    background-color: var(--text-color);
    border-radius: 50%
}

.education__line{
    display:block;
    width: 1px;
    height: 100%;
    background-color: var(--text-color);
    transform: translate(6px, -7px);
}

.about__title{
    font-size:var(--small-font-size);
    font-weight: var(--font-medium);
}
/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .education__container{
        margin-left: auto;
        margin-right: auto;
    }
  }

@media screen and (max-width: 768px) {
    .education__container{
        margin-left:var(--mb-1-5);
        margin-right: var(--mb-1-5);
    }
  }
@media screen and (max-width: 576px) {
    .education__sections{
        grid-template-columns: initial;
    }

    .education__button{
        margin:0 var(--mb-0-75);
    }
  }
  
  /* For small devices */
  @media screen and (max-width: 350px) {
    .education__data{
        gap:0.5rem;
    }


  }